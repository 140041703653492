import React from 'react';
import config from 'config';

import { Card, Container,  Button } from 'semantic-ui-react';
import { CardHeader,  CardContent } from '@material-ui/core'
import { useParams } from 'react-router-dom';
import printing from 'middleware/printing/handler';

interface IRouteParams {
    galleryStoryId: string;
}

const GalleryStoryPage: React.FC = () => {
    const { galleryStoryId } = useParams<IRouteParams>();
    const [record, setApiData] = React.useState<any>();

    React.useEffect(() => {
        (async () => {
            const data = await fetch(config.api, {
                method: 'GET',
            }).then(result => result.json());
            const apiRecord = data.records.find((o: any) => o.id === galleryStoryId);
            setApiData(apiRecord);
        })()

    }, [galleryStoryId]);

    const print = React.useCallback(() => {
        (async () => {
         await printing.print(record);
            // setCanvasData(canvas);
        })();
    }, [record])


    return <Container style={{ marginTop: 20 }}>
        <Button color="teal" onClick={print}>Print</Button>

        {
            record && <Card fluid={true}>
                <CardHeader title={record.fields["Child's Name"]} subheader={`Aged ${record.fields["Child's Age"]}`} />
                <img src={record.fields["Drawing"][0].url} />

                <CardContent>
                    {record.fields["Story"]}
                </CardContent>
            </Card>
        }
    </Container>

}

export default GalleryStoryPage;