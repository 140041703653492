import React from 'react'
import { Modal, Image, Menu, Icon } from 'semantic-ui-react'
import printing from 'middleware/printing/handler';
import { IRecord } from 'models/record.model';

interface IModalViewProps {
    closeHandler: () => void;
    content?: IRecord
    open?: boolean;
}

const ModalView: React.FC<IModalViewProps> = ({ open, closeHandler, content }) => {

    const print = React.useCallback(() => {
        (async () => {
            if (content) {
                await printing.print(content);
            }
            // setCanvasData(canvas);
        })();
    }, [content]);

    return content ? <Modal closeOnDimmerClick={false} open={open} closeIcon={true} onClose={closeHandler}>
        <Modal.Content>
            <Modal.Header>{`${content["Child's Name"]} aged ${content["Child's Age"]}`}</Modal.Header>
        </Modal.Content>
        <Modal.Content>
            <Image centered={true} src={content["Drawing"][0].thumbnails.full.url} size="big" />
        </Modal.Content>
        <Modal.Content>
            <Modal.Description>
                {content["Story"]}
            </Modal.Description>
            <Menu secondary={true} borderless={true}>
                <Menu.Menu position="right">
                    <Menu.Item icon={true} link={true} href={content["PDF"][0].url} target="_blank">
                        <Icon name="download" />
                    </Menu.Item>
                    {/* <Menu.Item icon={true} onClick={() => { }}>
                        <Icon name="heart outline" />
                    </Menu.Item> */}
                </Menu.Menu>
            </Menu>
        </Modal.Content>
    </Modal> : null;
}

export default ModalView;