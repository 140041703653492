import React from 'react';
import { Dropdown, Card, Container, Grid, Image, Menu, DropdownItemProps, Segment, Icon } from 'semantic-ui-react';
import printing from 'middleware/printing/handler';

import { ReactComponent as Logo } from 'logo.svg';

import config from 'config';
import ModalView from 'components/modal-view';
import { IRecord } from 'models/record.model';



const IndexPage: React.FC = () => {
    const [apiData, setApiData] = React.useState<any[]>();
    const [filteredData, setFilteredData] = React.useState<any[]>();
    const [searchOptions, setSearchOptions] = React.useState<DropdownItemProps[][]>();
    const [searchCriteria, setSearchCriteria] = React.useState<string[]>([]);

    const [modalContent, toggleModal] = React.useState<IRecord>();


    // Load Data

    React.useEffect(() => {
        (async () => {
            const data = await fetch(config.api, {
                method: 'GET',
            }).then(result => result.json());
            setApiData(data.records);
        })();
    }, []);

    // Set up Filters

    React.useEffect(() => {
        if (apiData) {
            const tagData = apiData?.reduce((prev: DropdownItemProps[], current: any) => {
                const tags = current.fields["Tags"];
                if (tags) {
                    const newTags = tags.filter((tag: any) => !prev.find(existing => existing.key === tag));
                    return [...prev, ...newTags.map((tag: any) => ({ key: tag, icon: 'tags', text: tag, value: tag }))]
                }
                return prev;
            }, []);
            const countryData = apiData?.reduce((prev: DropdownItemProps[], current: any) => {
                const country = current.fields["Country"];
                if (country && !prev.find(existing => existing.key === country)) {
                    return [...prev, { key: country, icon: 'flag', text: country, value: country }]
                }
                return prev;
            }, []);

            const nameData = apiData?.filter(a => a.fields["Child's Name"] !== undefined)
            .reduce((prev: DropdownItemProps[], current: any) => {
                const user: string[] = current.fields["Child's Name"].split(' ');
                if (user) {
                    const searchVars = user.filter((str: any) => !prev.find(existing => existing.key === str));
                    return [...prev, ...searchVars.map((tag: any) => ({ key: tag, icon: 'user', text: tag, value: tag }))]
                }
                return prev;
            }, []);

            setSearchOptions([...tagData, ...countryData, ...nameData]);
        }
    }, [apiData, setSearchOptions]);


    // Filter Data

    React.useEffect(() => {
        if (apiData) {
            setFilteredData(apiData?.filter(record => {
                let show =record.fields["Child's Name"] && record.fields["Drawing"] && record.fields["PDF"] ? true : false;
                const tags: string[] = [].concat(record.fields["Tags"]).filter(o => o);
                const country: string = record.fields["Country"];
                const story: string = record.fields["Story"];
                const user: string = record.fields["Child's Name"];
                return show && searchCriteria.every(criteria =>
                    tags.map(o => o.toLowerCase()).includes(criteria.toLowerCase()) ||
                    // story.toLowerCase().includes(criteria.toLowerCase()) ||
                    country.toLowerCase() === criteria.toLowerCase() ||
                    user.toLowerCase().includes(criteria.toLowerCase())
                )
            }));
        }

    }, [apiData, searchCriteria, setFilteredData]);


    const print = React.useCallback((record: { [key: string]: string }) => {
        (async () => {
            await printing.print(record);
            // setCanvasData(canvas);
        })();
    }, []);

    const changeSearch = React.useCallback((value: string[]) => {
        setSearchCriteria(value);

    }, [searchCriteria, setSearchCriteria])


    return (<Container style={{ paddingTop: 20 }}>
        <Logo width={250} />
        <Menu secondary={true}>
            {
                searchOptions && <Dropdown
                    className='icon'
                    fluid={true}
                    floating={true}
                    labeled={true}
                    icon="search"
                    multiple={true}
                    selection={true}
                    search={true}
                    onChange={(e, { value }) => changeSearch(value as string[])}
                    options={searchOptions}
                    text={'Search...'} />
            }

        </Menu>
        <ModalView open={modalContent ? true : false} content={modalContent} closeHandler={() => toggleModal(undefined)} />
        <Grid stackable={true} columns={3}>
            {
                [...Array(3)].map((_, i) => <Grid.Column stretched={true} key={`col_${i}`}>
                    {
                        filteredData && filteredData.filter((_, idx) => (idx + (i === 2 ? 1 : i === 1 ? 2 : 3)) % 3 === 0).map((record, index) => {
                            return <Card key={`card_${index}`} fluid={true} className="card-reveal" >
                                <Image src={record.fields["Drawing"][0].thumbnails.full.url} style={{ width: '100%' }} />
                                <div className="card-reveal-content">
                                    <Segment padded="very" basic={true}>
                                        <Card.Header className="right">{record.fields["Country"]}</Card.Header>
                                        <Card.Header>{record.fields["Child's Name"]}</Card.Header>
                                        <Card.Meta>{`Aged ${record.fields["Child's Age"]}`}</Card.Meta>
                                        <Card.Description>{record.fields["Story"]}</Card.Description>
                                    </Segment>
                                    <Menu attached="bottom" borderless={true}>
                                        <Menu.Menu position="right">
                                            <Menu.Item icon={true} onClick={() => {
                                                console.log(record.fields);
                                                toggleModal(record.fields)
                                            }}>
                                                <Icon name="expand arrows alternate" />
                                            </Menu.Item>
                                            <Menu.Item icon={true} link={true} href={record.fields["PDF"][0].url} target="_blank">
                                                <Icon name="download" />
                                            </Menu.Item>
                                            {/* <Menu.Item icon={true} onClick={() => { }}>
                                                <Icon name="heart outline" />
                                            </Menu.Item> */}
                                        </Menu.Menu>
                                    </Menu>
                                </div>
                            </Card>

                        })
                    }

                </Grid.Column>)
            }

        </Grid>
    </Container>);
}

export default IndexPage;