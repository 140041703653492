import { createCanvas, loadImage } from 'canvas'
import jsPDF from 'jspdf'
import { IRecord } from 'models/record.model';
import pdfKit from 'pdfkit'
import SueEllenFrancisco from 'font';

const printing = {

    print: async (record: IRecord) => {

        const Drawing = record["Drawing"];

        const doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            compressPdf: true
        });

        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();

        doc.addFileToVFS("SueEllenFrancisco.ttf", SueEllenFrancisco);
        doc.addFont('SueEllenFrancisco.ttf', 'SueEllenFrancisco', 'normal');


        doc.setFont('SueEllenFrancisco'); // set font
        doc.setFontSize(40);
        doc.text(`${record["Child's Name"]} aged ${record["Child's Age"]}`, 10, 20);

        const image = await loadImage(Drawing[0].url, { crossOrigin: 'anonymous' });

        const landscape = image.width > image.height;
        const canvas = createCanvas(image.width, image.height, "svg");
        const ctx = canvas.getContext('2d');
        if (landscape) {
            ctx.save();
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(90 * Math.PI / 180);

            ctx.drawImage(image, 0, 0, image.width, image.height,     // source rectangle
                -canvas.height / 2, -canvas.width / 2, canvas.height, canvas.width); // destination rectangle

            ctx.restore();
        }
        else {

            ctx.drawImage(image, 0, 0, image.width, image.height,     // source rectangle
                0, 0, canvas.width, canvas.height); // destination rectangle
        }

        const imgWidth = width - 75;
        const imgHeight = height / width * imgWidth;

        doc.addImage(canvas.toDataURL(), 'JPEG', 37.5, 30, imgWidth, imgHeight, undefined, 'FAST');

        doc.setFontSize(20)
        const splitStory = doc.splitTextToSize(record["Story"], 180);
        doc.text(splitStory, 10, imgHeight + 40)

        const logo = await loadImage(require('logo.png'));
        doc.addImage(logo, 'PNG', 10, height - 20, 40, 18.6, undefined, 'FAST');

        const link = 'Shared by www.colourourstory.com with love'
        const linkWidth = doc.getTextWidth(link) + 10
        doc.textWithLink(link, width - linkWidth, height - 20, { url: 'https://www.colourourstory.com/', align: 'right' });

        doc.save('testing-pdf-print');

    }

}
export default printing;