import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


import IndexPage from 'pages';
import GalleryStoryPage from 'pages/gallery-story';


function App() {

  return (
    <Router>
      <Switch>
        <Route path="/" exact={true} component={IndexPage} />
        <Route path="/gallery/:galleryStoryId/story" exact={true} component={GalleryStoryPage} />
      </Switch>
    </Router>
  );
}

export default App;
